
import { ReactElement, createContext, useContext, useState } from "react";
import ChannelModel from "../../model/channel-model";

const HomeContext = createContext<HomeType>(null!)

interface HomeType {
    isDrawerOpen: boolean
    setOpenDrawer: (isDrawerOpen: boolean) => void
    channel: ChannelModel | undefined
    setChannel: (channel: ChannelModel | undefined) => void
}

export function useHomeProvider() {
	return useContext(HomeContext)
}


export function HomeProvider({ children }: { children: ReactElement }) {
	const [isDrawerOpen, setOpenDrawer] = useState<boolean>(false)
	const [channel, setChannel] = useState<ChannelModel | undefined>()

    const value = {
        isDrawerOpen,
        setOpenDrawer,
        channel,
        setChannel,
    }
	return (
		<HomeContext.Provider value={value}>
			{children}
		</HomeContext.Provider>
	)
}
