import { ClipboardModel } from "../../../../model/clipboard-model";
import linkifyStr from "linkify-string";
import * as linkify from "linkifyjs";
import { useEffect, useState } from "react";
import './index.css'
import { HttpBuilder } from "../../../../http/http_builder";
import { ApiPath } from "../../../../http/api-path";
import { MetaModel } from "../../../../model/meta-model";
import { useRootProvider } from "../../../root/provider";

interface ItemClipboardType {
    item: ClipboardModel
    onShowShare?: () => {},
    onImagePressed?: (link: string) => void,
}

const ItemClipboard = (props: ItemClipboardType) => {
    const [components, setComponents] = useState<{
        type: string;
        value: string;
        isLink: boolean;
        href: string;
        start: number;
        end: number;
    }[]>()
    const [meta, setMeta] = useState<MetaModel | undefined>()
    const rootProvider = useRootProvider()

    const content = props.item.content
    const imageLink = components?.find((e) => e.type === 'url')?.href


    function onCopy() {
        navigator.clipboard.writeText(props.item.content ?? '')
        rootProvider.makeToast('Copied!')
    }

    function _showShare(): void {
        if (props.onShowShare != null)
            props.onShowShare()
    }

    async function getMeta() {
        if (!imageLink) return
        if (imageLink.startsWith(process.env.REACT_APP_BASE_URL ?? '')) return
        try {
            var cache = localStorage.getItem(imageLink)

            if (cache != null) {
                const obj = JSON.parse(cache)
                if (!!Object.keys(obj).length) {
                    const metaCache = MetaModel.fromJson(obj)
                    if (metaCache.expired && !meta?.isExpired()) {
                        setMeta(metaCache)
                        return
                    }
                }
            }
            const result = await HttpBuilder.main()
                .setPath(ApiPath.meta)
                .setQuery({
                    url: imageLink,
                })
                .setFromJson(MetaModel.fromJson)
                .get<MetaModel>()

            if (!result.isOk()) return
            setMeta(result.data)
            if (result.data) {
                localStorage.setItem(imageLink, JSON.stringify({
                    ...result.data,
                    expired: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
                }))
            }

        } catch (_) { }
    }


    useEffect(() => {
        if (content) {
            const result = linkify.find(content)
            setComponents(result)
        }
    }, [])

    useEffect(() => {
        getMeta()
    }, [imageLink])

    function _onShowImage(url?: string): void {

        if (props.onImagePressed && !!url) {
            props.onImagePressed(url)
        }
    }

    function _onOpenLink(): void {
        window.open(imageLink, '_blank')
    }

    return (
        <tr>
            <td className="text-nowrap">{props.item.id}</td>
            <td className="col-12 position-relative" style={{ whiteSpace: 'pre-line' }}>
                <p dangerouslySetInnerHTML={{ __html: linkifyStr(content!!, { target: '_blank' }) }} />
                {
                    meta?.isNotEmpty() &&
                    <div
                        className="d-flex flex-column border-success p-2 border-1 rounded-2 align-items-start mt-3"
                        style={{ border: '1px solid' }}>
                        <div onClick={_onOpenLink} className="max-line-2" role="button"><b>{meta.title}</b></div>
                        {
                            meta.desc &&
                            <div className="mt-1 d-md-block d-none" dangerouslySetInnerHTML={{ __html: linkifyStr(meta.desc!!, { target: '_blank' }) }}></div>
                        }
                        <img src={meta.image} alt="" className="rounded-2 col-4 col-md-3 mt-3" onClick={() => _onShowImage(meta.image)} role="button" style={{ objectFit: 'contain' }} />

                    </div>
                }
                {
                    !meta?.isNotEmpty() &&
                    <img src={imageLink} alt="" className="rounded-2 col-4 col-md-3 mt-3" onClick={() => _onShowImage(imageLink)} role="button" style={{ objectFit: 'contain' }} />
                }
                {
                    props.item.isFile() &&
                    <a href={`${props.item.content}?type=download`} target="_blank" rel="noopener noreferrer" download>
                        <button
                            className="position-absolute "
                            style={{ top: 8, right: 12, zIndex: 1, padding: 0, width: 36, height: 36 }}>⬇️</button>
                    </a>

                }
            </td>
            <td>
                <div className="d-flex">
                    <button className="d-none d-md-block me-2" onClick={_showShare}>🔗</button>
                    <button onClick={onCopy}>📋</button>
                </div>
            </td>
        </tr>
    );
}

export default ItemClipboard
