import { useSearchParams } from "react-router-dom";
import ChannelModel from "../../../../model/channel-model";
import '../index.css'

interface ItemChannelType {
    channel: ChannelModel
    onPressed: () => void
}

const ItemChannel = (props: ItemChannelType) => {
    const [search] = useSearchParams()


    return (
        <div className={`w-100 item-channel ${+(search.get('channel') ?? 0) === props.channel.id ? 'active' : ''}`} onClick={props.onPressed}>
            <div className="d-flex p-2 justify-content-between">
                <div>
                    {
                        props.channel.name
                    }
                </div>
                <div >
                    {
                        props.channel.hasPassword && <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" opacity={.3}><path d="M19 10H20C20.5523 10 21 10.4477 21 11V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11C3 10.4477 3.44772 10 4 10H5V9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9V10ZM5 12V20H19V12H5ZM11 14H13V18H11V14ZM17 10V9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9V10H17Z"></path></svg>
                            // : <svg width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"  opacity={.7}><path d="M7 10H20C20.5523 10 21 10.4477 21 11V21C21 21.5523 20.5523 22 20 22H4C3.44772 22 3 21.5523 3 21V11C3 10.4477 3.44772 10 4 10H5V9C5 5.13401 8.13401 2 12 2C14.7405 2 17.1131 3.5748 18.2624 5.86882L16.4731 6.76344C15.6522 5.12486 13.9575 4 12 4C9.23858 4 7 6.23858 7 9V10ZM5 12V20H19V12H5ZM10 15H14V17H10V15Z"></path></svg>
                    }
                </div>
            </div>
            <hr className="p-0 m-0" />
        </div>
    );
}

export default ItemChannel
