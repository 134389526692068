
export class MetaModel {
    title?: string
    desc?: string
    type?: string
    image?: string
    expired?: Date

    static fromJson(json: MetaModel): MetaModel {
        const data = Object.assign(new MetaModel(), json)
        if (json.expired) {
            data.expired = new Date(json.expired)
        }
        return data
    }

    isExpired() {
        return (this.expired?.getTime() ?? 0) < Date.now()
    }

    isNotEmpty(): boolean {
        return (!!this.title || !!this.desc || !!this.type || !!this.image)
    }
}
